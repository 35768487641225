var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "main",
      {
        staticClass: "site-content",
        staticStyle: { "min-height": "0 !important" },
        attrs: { id: "wrapper" }
      },
      [
        _c("div", { staticClass: "site-content__inner" }, [
          _c("div", { staticClass: "site-content__holder" }, [
            _c(
              "figure",
              {
                staticClass: "page-thumbnail page-thumbnail--default",
                staticStyle: { top: "0px" }
              },
              [
                _c("img", {
                  staticClass: "page-bg-logo",
                  attrs: { src: require("@/assets/img/logo.png"), alt: "" }
                }),
                _c("div", { staticClass: "ncr-page-decor" }, [
                  _c("div", { staticClass: "ncr-page-decor__layer-1" }, [
                    _c("div", { staticClass: "ncr-page-decor__layer-bg" })
                  ]),
                  _c("div", { staticClass: "ncr-page-decor__layer-2" }),
                  _c("div", { staticClass: "ncr-page-decor__layer-3" }, [
                    _c("div", { staticClass: "ncr-page-decor__layer-bg" })
                  ]),
                  _c("div", { staticClass: "ncr-page-decor__layer-4" }),
                  _c("div", { staticClass: "ncr-page-decor__layer-5" }),
                  _c("div", { staticClass: "ncr-page-decor__layer-6" })
                ])
              ]
            ),
            _c("h1", { staticClass: "page-title h3" }, [_vm._v("Contact us")]),
            _c("div", { staticClass: "page-content" }, [
              _c("h4", { staticClass: "text-sm" }, [_vm._v("Contact info")]),
              _c("p", [
                _vm._v(
                  " If you have any questions, just send us and email and don’t forget to follow and like all our social accounts to keep updated! "
                )
              ]),
              _c("div", { staticClass: "info-box" }, [
                _c("div", { staticClass: "info-box__label" }, [
                  _vm._v("General inquiries")
                ]),
                _c("div", { staticClass: "info-box__content" }, [
                  _c("a", { attrs: { href: "mailto:#" } }, [
                    _vm._v("inquiries"),
                    _c("span", { staticClass: "color-primary" }, [_vm._v("@")]),
                    _vm._v("necromancers.com")
                  ])
                ])
              ]),
              _c("ul", { staticClass: "social-menu social-menu--links" }, [
                _c("li", [
                  _c("a", {
                    attrs: { href: "https://www.facebook.com/danfisher.dev/" }
                  })
                ]),
                _c("li", [
                  _c("a", {
                    attrs: { href: "https://twitter.com/danfisher_dev" }
                  })
                ]),
                _c("li", [_c("a", { attrs: { href: "twitch.com" } })]),
                _c("li", [_c("a", { attrs: { href: "discordapp.com" } })])
              ]),
              _c("h4", { staticClass: "text-sm" }, [_vm._v("Join our team")]),
              _c("p", [
                _vm._v(
                  " We’re always looking for new talent to join our teams. If you wanna join us, just send us and email and we’ll get back to you! "
                )
              ]),
              _c("div", { staticClass: "info-box" }, [
                _c("div", { staticClass: "info-box__label" }, [
                  _vm._v("Max Parker - Recruiter")
                ]),
                _c("div", { staticClass: "info-box__content" }, [
                  _c("a", { attrs: { href: "mailto:#" } }, [
                    _vm._v("mp-recruit"),
                    _c("span", { staticClass: "color-primary" }, [_vm._v("@")]),
                    _vm._v("necromancers.com")
                  ])
                ])
              ]),
              _c("div", { staticClass: "info-box" }, [
                _c("div", { staticClass: "info-box__label" }, [
                  _vm._v("Be our partner!")
                ]),
                _c("div", { staticClass: "info-box__content" }, [
                  _c("a", { attrs: { href: "mailto:#" } }, [
                    _vm._v("partners"),
                    _c("span", { staticClass: "color-primary" }, [_vm._v("@")]),
                    _vm._v("necromancers.com")
                  ])
                ])
              ]),
              _c("span", { staticClass: "spacer-lg" }),
              _c("h4", [_vm._v("Send us a message")]),
              _c("form", { staticClass: "form", attrs: { action: "#" } }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "input-name",
                          placeholder: "Your Name"
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "input-email",
                          placeholder: "Your Email"
                        }
                      })
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("textarea", {
                        staticClass: "form-control",
                        attrs: {
                          name: "input-message",
                          cols: "30",
                          rows: "5",
                          id: "input-message",
                          placeholder: "Your Message"
                        }
                      })
                    ])
                  ])
                ]),
                _c("button", { staticClass: "btn btn-secondary" }, [
                  _vm._v("Send message")
                ])
              ])
            ]),
            _c(
              "div",
              {
                staticClass: "gm-map gm-map--aside",
                attrs: {
                  "data-map-style": "necromancers",
                  "data-map-address": "645 5th Ave, New York, US",
                  "data-map-icon": "assets/img/map-marker.png",
                  "data-map-zoom": "15"
                }
              },
              [
                _c("div", { staticClass: "gm-map__info" }, [
                  _vm._v(" Necromancers"),
                  _c("br"),
                  _c("span", { staticClass: "color-primary" }, [
                    _vm._v("Headquarters")
                  ]),
                  _c("address", [
                    _vm._v("1284 W 52nd Street "),
                    _c("br"),
                    _vm._v("Suite 8, New York")
                  ])
                ])
              ]
            )
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }